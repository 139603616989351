<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="state.username"
      name="用户名"
      label="用户名"
      placeholder="用户名"
      autocomplete
      :rules="[{ required: true, message: '请填写用户名' }]"
    />
    <van-field
      v-model="state.password"
      type="password"
      name="密码"
      label="密码"
      placeholder="密码"
      autocomplete
      :rules="[{ required: true, message: '请填写密码' }]"
    />
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
  </van-form>
</template>

<script>
import { Form, Field, Button } from 'vant'
import { reactive } from 'vue'

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  setup() {
    const state = reactive({
      username: '',
      password: '',
    })
    const onSubmit = (values) => {
      console.log('submit', values)
    }

    return {
      state,
      onSubmit,
    }
  },
}
</script>
